



import { useErrors, useQuery, useResult } from '@/app/composable';
import { ExecutionHistory } from '@/modules/workflow-designer/components/execution-history';
import { defineComponent } from '@vue/composition-api';
import GET_JOB_WORKFLOW from '../graphql/jobWorkflow.graphql';

export default defineComponent({
    name: 'DataCheckinJobsExecutionHistory',
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    components: { ExecutionHistory },
    setup: (props, { root }) => {
        const { checkGQLAuthentication } = useErrors(root.$route);
        const { result, onError } = useQuery(
            GET_JOB_WORKFLOW,
            { id: parseInt(`${props.id}`, 10) },
            { fetchPolicy: 'no-cache' },
        );
        onError(checkGQLAuthentication);
        const workflowId = useResult(result, null, (data: any) => data.job.workflowId);

        return { workflowId };
    },
});
