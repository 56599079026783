

































































































































































import { defineComponent, computed } from '@vue/composition-api';
import { FormBlock, Scrollbar } from '@/app/components';
import { useFilters } from '@/app/composable';

export default defineComponent({
    name: 'MappingInfo',
    model: {
        prop: 'configuration',
        event: 'change',
    },
    props: {
        domains: {
            type: Array,
            required: false,
        },
        concepts: {
            type: Array,
            required: false,
        },
        configuration: {
            type: Object,
            required: true,
        },
        locked: {
            type: Boolean,
            default: false,
        },
    },
    components: { FormBlock, Scrollbar },
    setup(props) {
        const standards = computed(() => {
            if (props.configuration.domain && props.domains) {
                const domain: any = props.domains.find((obj: any) => obj.id === props.configuration.domain.id);
                return domain ? domain.standardsMapping : [];
            }

            return [];
        });

        const { splitCamelCase } = useFilters();

        return { standards, splitCamelCase };
    },
});
