var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"w-full h-20 bg-white border border-gray-300 shadow",class:[
            {
                border: !_vm.disabled,
                ' bg-neutral-100': _vm.disabled,
                'opacity-50': _vm.field.dropped,
            } ]},[_c('div',{staticClass:"flex justify-between h-full",class:{
                'border-primary-700': _vm.selected,
                'border-transparent': !_vm.selected,
                'border-l-4 ': !_vm.disabled,
                'cursor-pointer': !_vm.isFinalized,
            },on:{"click":_vm.fieldClicked}},[_c('div',{staticClass:"flex flex-col w-full p-2"},[_c('div',[_c('h2',{staticClass:"flex items-center justify-between text-lg font-bold text-neutral-800 min-h-7"},[_c('div',{staticClass:"flex items-center float-left space-x-2"},[_c('span',{staticClass:"text-primary-700"},[_vm._v(_vm._s(_vm.field.title)),(_vm.field.order)?_c('span',[_vm._v("_"+_vm._s(_vm.field.order))]):_vm._e()]),_c('span',{staticClass:"px-2 text-sm capitalize rounded text-neutral-100 bg-neutral-600"},[_vm._v(" "+_vm._s(_vm.field.type)+" ")]),(_vm.modified && !_vm.isFinalized)?_c('ExclamationIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('This field has been revised in the Mapping step'),expression:"'This field has been revised in the Mapping step'"}],staticClass:"w-5 h-5 text-secondary-600"}):_vm._e()],1)]),_c('field-path',{attrs:{"field":_vm.field}})],1)]),_c('div',{staticClass:"flex flex-col items-center justify-center p-2 border-l border-gray-400 w-44"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.tooltipMessage),expression:"tooltipMessage"}]},[_c('toggle',{attrs:{"disabled":_vm.isFinalized || !_vm.field.canBeIndexed,"additionalInputClasses":_vm.toggleColor},on:{"change":function($event){return _vm.updateIndex()}},model:{value:(_vm.field.index),callback:function ($$v) {_vm.$set(_vm.field, "index", $$v)},expression:"field.index"}})],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }