



























































































































































































































































































































import { defineComponent, computed, ref, watch, reactive } from '@vue/composition-api';
import { ValidationProvider, extend, ValidationObserver } from 'vee-validate';
import { required, min_value, max_value } from 'vee-validate/dist/rules'; // eslint-disable-line
import { OrbitSpinner } from 'epic-spinners';
import { useAxios } from '@vue-composable/axios';
import { useFilters } from '@/app/composable';
import { KafkaAPI } from '@/modules/data-checkin/api';
import {
    FormBlock,
    JsonParser,
    TwButton,
    ConfirmModal,
    InputErrorIcon,
    AlertBanner,
    SvgImage,
    CopyToClipboardButton,
} from '@/app/components';
import { useHarvester } from '../../composable';

extend('required', {
    ...required,
});

extend('min_value', min_value);
extend('max_value', max_value);

const { formatBytes } = useFilters();

export default defineComponent({
    name: 'KafkaConfiguration',
    model: {
        prop: 'configuration',
    },
    props: {
        configuration: {
            type: Object,
            required: true,
        },
        sample: {
            type: [Object, Array],
            required: false,
        },
        files: {
            type: Object,
            required: true,
        },
        activeTab: {
            type: Number,
            required: true,
        },
        jobId: {
            type: Number,
            required: true,
        },
        completed: {
            type: Boolean,
            default: true,
        },
        running: {
            type: Boolean,
            default: true,
        },
        initializing: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        FormBlock,
        ValidationProvider,
        JsonParser,
        ValidationObserver,
        TwButton,
        ConfirmModal,
        OrbitSpinner,
        InputErrorIcon,
        AlertBanner,
        SvgImage,
        CopyToClipboardButton,
    },
    setup(props, { root, emit }) {
        const { exec } = useAxios(true);
        const sampleFile = computed(() => props.files.sample);
        const sampleRef = ref<any>(null);
        const dateYesterday = computed(() => {
            const date = new Date();
            date.setDate(date.getDate() - 1);
            return date;
        });
        const kafkaValidationRef = ref<any>(null);
        const showResetPasswordModal = ref<boolean>(false);
        const resettingPassword = ref<boolean>(false);
        const showPasswordBanner = ref<boolean>(true);
        const disableDataTypeChange = computed(() => props.completed);
        const invalidFormat = ref<boolean>(false);
        const initializationFailed = ref<boolean>(false);

        const tomorrow = computed(() => {
            const date = new Date();
            return date.setDate(date.getDate() + 1);
        });

        const resetButton = computed(() => {
            if (resettingPassword.value) return 'Resetting Password...';
            return 'Reset Password';
        });

        const connectionDetails = reactive({
            url: null,
            topic: null,
            saslMechanism: null,
            username: '',
            password: null,
        });

        const { parseJSON, checkInvalidXML, parseXML, clearFiles } = useHarvester(root, emit);

        if (!props.initializing && !props.configuration.params.username) {
            emit('initialize-kafka');
        }

        watch(
            () => props.configuration.params,
            (params) => {
                if (params) {
                    initializationFailed.value = false;
                    if (props.configuration.params.username) {
                        let brokers = props.configuration.params.url.split(',').map((broker: string) => broker.trim());
                        brokers = brokers.join(', ');
                        connectionDetails.url = brokers;
                        connectionDetails.topic = props.configuration.params.topic;
                        connectionDetails.saslMechanism = props.configuration.params.saslMechanism;
                        connectionDetails.username = props.configuration.params.username;
                        connectionDetails.password = props.configuration.params.password;
                        props.configuration.params.password = null; // eslint-disable-line no-param-reassign
                        emit('save-step');
                    } else {
                        initializationFailed.value = true;
                    }
                }
            },
            { immediate: true },
        );

        const acceptedFiles = computed(() => {
            switch (props.configuration.fileType) {
                case 'json':
                    return '.json';
                case 'xml':
                    return '.xml';
                default:
                    return '.*';
            }
        });

        const validateAndProceed = async () => {
            if (kafkaValidationRef.value) {
                const valid = await kafkaValidationRef.value.validate();
                if (valid) {
                    emit('next-tab');
                }
            }
        };

        const validate = async () => {
            return kafkaValidationRef.value.validate();
        };

        const sampleUploaded = async (event: any) => {
            const file = event.target.files[0];
            await emit('sample-cropped', false);
            switch (props.configuration.fileType) {
                case 'json':
                    await emit('files-changed', { sample: file });
                    await parseJSON(file);
                    break;
                case 'xml':
                    invalidFormat.value = await checkInvalidXML(file);
                    if (invalidFormat.value) {
                        emit('files-changed', { sample: null });
                        (root as any).$toastr.e('Invalid xml format!', 'Error');
                    } else {
                        await emit('files-changed', { sample: file });
                        await parseXML(file);
                    }
                    break;
                default:
                // Do nothing
            }
            await validate();
        };

        const confirmResetPassword = () => {
            showResetPasswordModal.value = true;
        };

        const resetPassword = async () => {
            showResetPasswordModal.value = false;
            resettingPassword.value = true;
            exec(KafkaAPI.resetPassword(connectionDetails.username, undefined, props.jobId))
                .then((res) => {
                    if (res) connectionDetails.password = res.data.password;
                    resettingPassword.value = false;
                    (root as any).$toastr.s('Kafka password has been reset!', 'Success');
                })
                .catch(() => {
                    (root as any).$toastr.e('Failed to reset kafka password', 'Failed');
                    resettingPassword.value = false;
                });
        };

        const sampleMayBeCroppedMessage = computed(() =>
            props.configuration.fileType
                ? '- Sample may be cropped if required. Ensure that a small sample contains all necessary fields.'
                : '',
        );

        const sampleCroppedMessage = computed(() => (props.configuration.isSampleCropped ? 'cropped, ' : ''));

        return {
            tomorrow,
            resetButton,
            showResetPasswordModal,
            showPasswordBanner,
            connectionDetails,
            dateYesterday,
            kafkaValidationRef,
            formatBytes,
            sampleFile,
            sampleRef,
            sampleUploaded,
            validate,
            validateAndProceed,
            confirmResetPassword,
            resetPassword,
            resettingPassword,
            clearFiles,
            acceptedFiles,
            disableDataTypeChange,
            invalidFormat,
            sampleCroppedMessage,
            sampleMayBeCroppedMessage,
            initializationFailed,
        };
    },
});
