














































































import * as R from 'ramda';
import { computed, defineComponent } from '@vue/composition-api';
import { GeneralizationMethod } from '../../../constants';
import { useAnonymisation } from '../../../composable';

export default defineComponent({
    name: 'FieldPreview',
    props: {
        field: {
            type: Object,
            required: true,
        },
        sample: {
            type: Array,
            required: true,
        },
        maxAutoLevels: {
            type: Number,
            default: 3,
        },
    },
    setup(props) {
        const { getMaxStringLength, getLevelCount, getNextLevel } = useAnonymisation();

        const getIntervalRule = (levelIndex: number, level: any) => ({
            level: levelIndex,
            message: 'Values will be generalized to arithmetic intervals of size',
            interval: level.interval,
        });

        const getNumericalGroupRule = (levelIndex: number, level: any) => {
            const rule: any = {
                level: levelIndex,
                message: 'Values will be generalized to numerical groups as follows:',
                groupRules: [],
            };
            level.forEach((group: any) => {
                let { label } = group;
                if (!label || label.length === 0) {
                    label = `${group.from}-${group.to}`;
                }
                rule.groupRules.push({
                    fromMessage: 'Values from',
                    fromValue: group.from,
                    toMessage: 'to',
                    toValue: group.to,
                    labelMessage: 'will be replaced with the label',
                    labelValue: label,
                });
            });
            return rule;
        };

        const getMaskingRule = (levelIndex: number, maxCharacters: number, maskingChar: string) => {
            if (levelIndex === 1) {
                return {
                    level: levelIndex,
                    charactersMessage: 'The',
                    charactersValue: 'last character',
                    replaceMessage: 'of the values will be replaced with the masking character',
                    replaceValue: maskingChar,
                };
            }
            if (levelIndex === maxCharacters) {
                return {
                    level: levelIndex,
                    charactersMessage: '',
                    charactersValue: 'All characters',
                    replaceMessage: 'of the values will be replaced with the masking character',
                    replaceValue: maskingChar,
                };
            }
            return {
                level: levelIndex,
                charactersMessage: 'The',
                charactersValue: `last ${levelIndex} characters`,
                replaceMessage: 'of the values will be replaced with the masking character',
                replaceValue: maskingChar,
            };
        };

        const getDatetimeRule = (levelIndex: number) => {
            let resetParts = null;
            switch (levelIndex) {
                case 1:
                    resetParts = 'Seconds';
                    break;
                case 2:
                    resetParts = 'Minutes and seconds';
                    break;
                case 3:
                    resetParts = 'Hours, minutes and seconds';
                    break;
                case 4:
                    resetParts = 'Day, hours, minutes and seconds';
                    break;
                case 5:
                    resetParts = 'Month, day, hours, minutes and seconds';
                    break;
                case 6:
                    resetParts = 'All parts';
                    break;
                default:
                // do nothing
            }
            return {
                level: levelIndex,
                resetParts,
                beforeResetMessage: 'will be',
                afterResetMessage: 'from the datetime values',
            };
        };

        const getDateRule = (levelIndex: number) => {
            let resetParts = null;
            switch (levelIndex) {
                case 1:
                    resetParts = 'Day';
                    break;
                case 2:
                    resetParts = 'Month and day';
                    break;
                case 3:
                    resetParts = 'All parts';
                    break;
                default:
                // do nothing
            }
            return {
                level: levelIndex,
                resetParts,
                beforeResetMessage: 'will be',
                afterResetMessage: 'from the date values',
            };
        };

        const getTimeRule = (levelIndex: number) => {
            let resetParts = null;
            switch (levelIndex) {
                case 1:
                    resetParts = 'Seconds';
                    break;
                case 2:
                    resetParts = 'Minutes and seconds';
                    break;
                case 3:
                    resetParts = 'All parts';
                    break;
                default:
                // do nothing
            }
            return {
                level: levelIndex,
                resetParts,
                beforeResetMessage: 'will be',
                afterResetMessage: 'from the time values',
            };
        };

        const getBooleanRule = (levelIndex: number, show: boolean) => {
            if (show) {
                return {
                    level: levelIndex,
                    beforeActionMessage: 'The boolean values will be',
                    action: 'grouped together',
                    afterActionMessage: '',
                };
            }
            return {
                level: levelIndex,
                beforeActionMessage: 'The column will be',
                action: 'dropped',
                afterActionMessage: 'from the dataset',
            };
        };

        const getLevelRule = (levelIndex: number, maxCharacters: number, level: any) => {
            switch (props.field.generalization) {
                case GeneralizationMethod.Interval:
                    return getIntervalRule(levelIndex, level);
                case GeneralizationMethod.NumericalGroup:
                    return getNumericalGroupRule(levelIndex, level);
                case GeneralizationMethod.Masking:
                    return getMaskingRule(levelIndex, maxCharacters, props.field.options.maskingChar);
                case GeneralizationMethod.Datetime:
                    return getDatetimeRule(levelIndex);
                case GeneralizationMethod.Date:
                    return getDateRule(levelIndex);
                case GeneralizationMethod.Time:
                    return getTimeRule(levelIndex);
                case GeneralizationMethod.BooleanGroup:
                    return getBooleanRule(levelIndex, props.field.options.show);
                default:
                    return null;
            }
        };

        const previewRules = computed(() => {
            // The initial rule for all anonymisation types
            const rules = [
                {
                    level: 0,
                    beforeTitleMessage: 'The initial values of',
                    title: props.field.title,
                    afterTitleMessage: 'before anonymisation',
                },
            ];
            const sample: any = R.clone(props.sample);
            const maxCharacters = getMaxStringLength(sample, props.field);
            const levelCount = getLevelCount(maxCharacters, props.maxAutoLevels, props.field);

            let level = null;
            for (let i = 1; i <= levelCount; i += 1) {
                level = getNextLevel(i, level, props.field);
                rules.push(getLevelRule(i, maxCharacters, level));
            }
            return rules;
        });

        return {
            GeneralizationMethod,
            previewRules,
        };
    },
});
