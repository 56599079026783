<template>
    <div>
        <div v-if="selectedField.generalization === 'datetime'">
            <div class="flex items-center mt-3 mb-1 space-x-6">
                <div class="my-auto text-sm text-neutral-700">Generalize Until</div>
                <div class="w-40 pb-2">
                    <select
                        v-model="selectedField.options.finalLevel"
                        aria-label="Generalize Until"
                        class="w-full h-full py-1 pl-2 mt-2 bg-white form-select sm:text-sm sm:leading-5"
                    >
                        <option :value="3">Hours</option>
                        <option :value="4">Day</option>
                        <option :value="5">Month</option>
                        <option :value="6">Year (Decade)</option>
                    </select>
                </div>
            </div>
        </div>
        <div v-if="selectedField.generalization === 'date'">
            <div class="flex items-center mt-3 mb-1 space-x-6">
                <div class="my-auto text-sm text-neutral-700">Generalize Until</div>
                <div class="w-40 pb-2">
                    <select
                        v-model="selectedField.options.finalLevel"
                        aria-label="Generalize Until"
                        class="w-full h-full py-1 mt-2 bg-white form-select sm:text-sm sm:leading-5"
                    >
                        <option :value="2">Month</option>
                        <option :value="3">Year (Decade)</option>
                    </select>
                </div>
            </div>
        </div>
        <div v-if="selectedField.generalization === 'time'">
            <div class="flex items-center mt-3 mb-1 space-x-6">
                <div class="my-auto text-sm text-neutral-700">Generalize Until</div>
                <div class="w-40 pb-2">
                    <select
                        v-model="selectedField.options.finalLevel"
                        aria-label="Generalize Until"
                        class="w-full h-full py-1 mt-2 bg-white form-select sm:text-sm sm:leading-5"
                    >
                        <option :value="2">Minutes</option>
                        <option :value="3">Hours</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
    name: 'Datetime',
    props: {
        selectedField: {
            type: Object,
            required: true,
        },
    },
});
</script>
