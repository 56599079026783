



































































































































































































































































































import * as R from 'ramda';
import dayjs from 'dayjs';
import { defineComponent, computed, ref, watch } from '@vue/composition-api';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, integer, max } from 'vee-validate/dist/rules';
import { MultivalueEditor, InputErrorIcon } from '@/app/components';
import { dynamicValues } from '@/app/constants';
import { formatDate, getDynamicDate } from '../views/harvester/external-api/tranformations';
import { S } from '@/app/utilities';

extend('required', required);
extend('max_param_name', {
    ...max,
    message: (key: string, rule: any) => {
        return `You cannot add a parameter name longer than ${rule.length} characters`;
    },
});
extend('max_param_value', {
    ...max,
    message: (key: string, rule: any) => {
        return `You cannot add a parameter value longer than ${rule.length} characters`;
    },
});

extend('integer', { ...integer, message: 'Parameter value needs to be a number' });

export default defineComponent({
    name: 'AdditionalResponseData',
    model: {
        prop: 'additionalData',
        event: 'update',
    },
    props: {
        additionalData: {
            type: Array,
            default: () => [],
        },
        requestParams: {
            type: Array,
            default: () => [],
        },
        json: {
            type: [Object, Array, String],
            required: false,
        },
        isFinalized: {
            type: Boolean,
            default: false,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
    },
    components: { MultivalueEditor, ValidationObserver, ValidationProvider, InputErrorIcon },
    setup(props, { emit, root }) {
        const defaultFormat = 'ISO 8601';

        const paramValidationRef = ref<any>(false);

        const parameterTypes = computed(() => {
            const types = [
                { value: 'static', label: 'Static' },
                { value: 'dynamic', label: 'Dynamic' },
            ];

            if (props.requestParams.length > 0) {
                types.push({ value: 'request', label: 'From request parameters' });
            }
            return types;
        });

        const allDynamicValues = computed(() => {
            return dynamicValues.reduce((acc: any, val: string) => {
                acc.push({
                    label: val,
                    value: val,
                });
                return acc;
            }, []);
        });

        const allRequestParams = computed(() => {
            return (props.requestParams as Array<any>).reduce((acc: any, val: any) => {
                acc.push({
                    label: `${val.key.name} (${val.key.type})`,
                    value: val,
                });
                return acc;
            }, []);
        });

        const getValuePrefixLabel = (dynamicType: string) => {
            if (!dynamicType || !R.is(String, dynamicType)) return '';
            if (dynamicType.endsWith('_ago') || dynamicType.endsWith('_later')) return 'timestamp of';
            return '';
        };

        const getNeedsValue = (dynamicType: string) => {
            if (!dynamicType || !R.is(String, dynamicType)) return false;
            if (dynamicType.endsWith('_ago') || dynamicType.endsWith('_later')) return true;
            return false;
        };

        const getValueLabel = (dynamicType: any) => {
            if (!R.is(String, dynamicType) && S.has('key', dynamicType))
                return `${dynamicType.key.name} (${dynamicType.key.type})`;

            return dynamicType.split('_').join(' ');
        };

        const getRequiredFields = (type: string, dynamicType: string): string[] => {
            if (type === 'static' || type === 'request') {
                return ['key', 'type', 'value'];
            }
            if (type === 'dynamic') {
                if (dynamicType && getNeedsValue(dynamicType)) {
                    return ['key', 'type', 'dynamicType', 'value'];
                }

                if (dynamicType && !getNeedsValue(dynamicType)) {
                    return ['key', 'type', 'dynamicType'];
                }
                return ['key', 'type', 'dynamicType'];
            }

            return ['key', 'type'];
        };

        const getMultivalueEditorRequiredFields = (data: any) => {
            if (!data.key || !data.type) {
                return ['key', 'type'];
            }
            return getRequiredFields(data.type, data.dynamicType);
        };

        const siblingKeys = computed(() => {
            if (R.is(Array, props.json) && (props.json as any[]).length > 0) {
                return Object.keys((props.json as any[])[0]);
            }
            if (R.is(Object, props.json)) {
                return Object.keys(props.json as any[]);
            }

            return [];
        });

        const change = (data: any) => {
            const cleanData = [];
            for (let i = 0; i < data.length; i++) {
                if (siblingKeys.value.includes(data[i].key)) {
                    (root as any).$toastr.e(`Data key '${data[i].key}' already exists in response root`, 'Error');
                } else {
                    const requiredFields = getRequiredFields(data[i].type, data[i].dynamicType);
                    const cleanItem: any = {};
                    for (let f = 0; f < Object.keys(data[i]).length; f++) {
                        const field = Object.keys(data[i])[f];
                        if (requiredFields.includes(field)) {
                            cleanItem[field] = data[i][field];
                        }
                    }

                    if (cleanItem.type === 'static') {
                        cleanItem.displayValue = cleanItem.value;
                    } else if (cleanItem.type === 'request') {
                        if (cleanItem.value.value.type === 'dynamic') {
                            const now = new Date();
                            const timezoneOffset = now.getTimezoneOffset();
                            const utc = new Date(now.getTime() + timezoneOffset * 60 * 1000);
                            const parts = cleanItem.value.value.value.toString().split(':');
                            const format = cleanItem.value.value.format ? cleanItem.value.value.format : null;
                            const dynamicOption = parts[0];
                            const dynamicValue = parts[1] ? parseInt(parts[1], 10) : -1;
                            cleanItem.displayValue = formatDate(
                                format,
                                new Date(getDynamicDate(dynamicOption, utc, dynamicValue)),
                            );
                        } else {
                            cleanItem.displayValue = cleanItem.value.value.value;
                        }
                    } else {
                        cleanItem.displayValue = dayjs(new Date()).toISOString();
                        if (R.is(String, cleanItem.dynamicType)) {
                            cleanItem.format = defaultFormat;
                        }
                    }
                    cleanData.push(cleanItem);
                }
            }
            emit('update', cleanData);
            emit('change', cleanData);
        };

        // If requests params have changes we remove from the additional parameters any
        // additional parameter using the request param removed
        watch(
            () => props.requestParams,
            (updatedRequestParams: any[]) => {
                let hasChange = false;
                const cleanData = [];
                for (let i = 0; i < props.additionalData.length; i++) {
                    const additionalItem: any = props.additionalData[i];
                    if (additionalItem.type === 'request') {
                        if (
                            updatedRequestParams.filter(
                                (requestParam: any) =>
                                    JSON.stringify(requestParam) === JSON.stringify(additionalItem.value),
                            ).length > 0
                        ) {
                            cleanData.push(additionalItem);
                        } else {
                            hasChange = true;
                        }
                    } else {
                        cleanData.push(additionalItem);
                    }
                }

                if (hasChange) {
                    emit('update', cleanData);
                    emit('change', cleanData);
                }
            },
        );

        return {
            paramValidationRef,
            allDynamicValues,
            allRequestParams,
            parameterTypes,
            getNeedsValue,
            getMultivalueEditorRequiredFields,
            getValuePrefixLabel,
            getValueLabel,
            change,
        };
    },
});
