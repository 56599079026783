export const constraintOptions = [
    { id: 'RANGE', label: 'Range Constraint', description: 'Field values must be between a certain range' },
    { id: 'MANDATORY', label: 'Mandatory Constraint', description: 'Field values must not be null' },
    {
        id: 'REGULAR_EXPRESSION',
        label: 'Regular Expression Pattern Constraint',
        description: 'Field values must have an exact match with a certain regular expression',
    },
    { id: 'UNIQUE', label: 'Unique Constraint', description: 'Field values must be unique' },
    // {
    //     id: 'SET_MEMBERSHIP',
    //     label: 'Set-Membership Constraint',
    //     description: 'Field values must be in a certain code list',
    // },
    {
        id: 'CROSS_FIELD',
        label: 'Cross-field Validation Constraint',
        description: 'Field values must satisfy certain conditions which are executed from left to right',
    },
    {
        id: 'FOREIGN_KEY',
        label: 'Foreign-key Constraint',
        description: 'Field values must exist in a certain referenced field',
    },
];

export const outliersRuleOptions = [
    { id: 'DROP', label: 'Drop' },
    { id: 'DEFAULT_VALUE', label: 'Replace with default value' },
    { id: 'PREVIOUS_VALUE', label: 'Replace with previous value' },
    { id: 'MOST_FREQUENT_VALUE', label: 'Replace with most frequent value' },
    { id: 'MEAN_VALUE', label: 'Replace with mean value' },
    { id: 'MEDIAN_VALUE', label: 'Replace with median value' },
    { id: 'MIN_VALUE', label: 'Replace with min value' },
    { id: 'MAX_VALUE', label: 'Replace with max value' },
    { id: 'MOST_SIMILAR', label: 'Replace with most similar value' },
];

export const conditionsOptions = [
    { id: 'EQUAL', label: 'is equal to' },
    { id: 'NOT_EQUALS', label: 'is not equal to' },
    { id: 'GREATER_THAN', label: 'is greater than' },
    { id: 'SMALLER_THAN', label: 'is smaller than' },
];
