










































































import { defineComponent } from '@vue/composition-api';
import { PreviewTable, PreviewRules } from '.';
import { AnonymisationType, GeneralizationMethod, AnonymisationDescriptions } from '../../../constants';
import { useAnonymisation } from '../../../composable';

export default defineComponent({
    name: 'PreviewField',
    components: {
        PreviewTable,
        PreviewRules,
    },
    props: {
        field: {
            type: Object,
            required: true,
        },
        incompleteFields: {
            type: Object,
            required: true,
        },
        sample: {
            type: Array,
            required: true,
        },
    },
    setup() {
        const { getGeneralizationDescription } = useAnonymisation();

        return {
            AnonymisationType,
            GeneralizationMethod,
            AnonymisationDescriptions,
            getGeneralizationDescription,
        };
    },
});
