


































































































































































































































































































































































































































































































import * as R from 'ramda';
import { defineComponent, computed, ref, watch, reactive } from '@vue/composition-api';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, min_value, max_value } from 'vee-validate/dist/rules'; // eslint-disable-line
import ClickOutside from 'vue-click-outside';
import { OrbitSpinner } from 'epic-spinners';
import { useAxios } from '@vue-composable/axios';
import { KafkaAPI } from '@/modules/data-checkin/api';
import { useFilters } from '@/app/composable';
import { FormBlock, JsonParser, TwButton, InputErrorIcon } from '@/app/components';
import { useHarvester } from '../../composable';

const { formatBytes } = useFilters();

extend('required', {
    ...required,
});

extend('min_value', min_value);
extend('max_value', max_value);

export default defineComponent({
    name: 'ExternalKafkaConfiguration',
    model: {
        prop: 'configuration',
    },
    props: {
        configuration: {
            type: Object,
            required: true,
        },
        sample: {
            type: [Object, Array],
            required: false,
        },
        files: {
            type: Object,
            required: true,
        },
        activeTab: {
            type: Number,
            required: true,
        },
        jobId: {
            type: Number,
            required: true,
        },
        completed: {
            type: Boolean,
            default: true,
        },
        isFinalized: {
            type: Boolean,
            default: false,
        },
        jobConfig: {
            type: Object,
            required: false,
        },
        basePath: {
            type: String,
            required: true,
        },
        running: {
            type: Boolean,
            default: true,
        },
    },
    directives: {
        ClickOutside,
    },
    components: {
        FormBlock,
        ValidationProvider,
        JsonParser,
        ValidationObserver,
        TwButton,
        OrbitSpinner,
        InputErrorIcon,
    },
    setup(props, { root, emit }) {
        const separator = '||';
        const finalSample = ref<any>(props.configuration.processedSample);
        const { loading, exec } = useAxios(true);
        const sampleFile = computed(() => props.files.sample);
        const sampleRef = ref<any>(null);
        const dateYesterday = computed(() => {
            const date = new Date();
            date.setDate(date.getDate() - 1);
            return date;
        });
        const kafkaValidationRef = ref<any>(null);
        const resettingPassword = ref<boolean>(false);
        const disableDataTypeChange = computed(() => props.completed);

        const isSampleArray = computed(() => R.is(Array, props.configuration.response.data));

        const errorAlert: any = reactive({
            title: null,
            body: null,
            showIgnore: false,
        });

        const urlRef = ref<any>(null);
        const editingURL = ref<boolean>(true);
        const invalidFormat = ref<boolean>(false);
        const emptySampleResponse = ref<boolean>(false);
        const retrieveNewFileTypeSample = ref<boolean>(false);

        const saslMechanisms = ['None', 'PLAIN', 'SCRAM-SHA-256', 'SCRAM-SHA-512'];

        const connectionDetailsAlreadySaved = ref<any>(R.clone(props.configuration.params));

        const {
            changeFinalSample,
            limitResponse,
            parseJSON,
            checkInvalidXML,
            parseXML,
            clearFiles,
            reduceSampleValues,
        } = useHarvester(root, emit);

        const modifyFinalSample = (sample: any) => {
            finalSample.value = sample;
            changeFinalSample(sample, props.configuration.source);
        };

        const acceptedFiles = computed(() => {
            switch (props.configuration.fileType) {
                case 'json':
                    return '.json';
                case 'xml':
                    return '.xml';
                default:
                    return '.*';
            }
        });

        const modifiedErrorMessage = (error: any) => {
            const startsWith = error ? error.message.split(' ').slice(0, 2).join(' ') : null;
            errorAlert.title = 'Failed action';
            if (error.message) {
                if (error.message.includes('group coordinator')) {
                    errorAlert.body = `Failed to find Group Id ${props.configuration.params.groupId}. Please update the connection details to proceed.`;
                    return;
                }

                switch (startsWith) {
                    case 'SASL SCRAM':
                        errorAlert.body = `Authentication failed due to invalid credentials with SASL mechanism ${props.configuration.params.saslMechanism}. Please update the connection details to proceed.`;
                        break;
                    case 'SASL NONE':
                        errorAlert.body = `Authentication failed. Please update the connection details to proceed.`;
                        break;
                    case 'Connection error:':
                    case 'Failed to':
                        errorAlert.body = `Connecting to ${props.configuration.params.url} failed. Please update the connection details to proceed.`;
                        break;
                    case 'Not authorized':
                        errorAlert.body = `Not authorized to access topic ${props.configuration.params.topic}. Please update the connection details to proceed.`;
                        break;
                    default:
                        errorAlert.body = error ? `Testing failed with message: ${error.message}` : null;
                }
            }
        };

        const validateAndProceed = async () => {
            if (kafkaValidationRef.value) {
                const valid = await kafkaValidationRef.value.validate();
                if (valid) {
                    if (
                        JSON.stringify(connectionDetailsAlreadySaved.value) !==
                            JSON.stringify(props.configuration.params) ||
                        (retrieveNewFileTypeSample.value && !props.configuration.isSampleUploaded)
                    ) {
                        await emit('sample-uploaded', null);
                        emit('files-changed', { sample: null });
                        changeFinalSample(null, props.configuration.source);
                        if (props.configuration.response.selectedItems) {
                            props.configuration.response.selectedItems.splice(0);
                        }
                        finalSample.value = null;
                        props.configuration.isSampleUploaded = null; // eslint-disable-line no-param-reassign
                        props.configuration.response.data = null; // eslint-disable-line no-param-reassign
                        emptySampleResponse.value = false;
                        retrieveNewFileTypeSample.value = false;
                    }

                    await emit('update-connection-details', props.configuration.params);
                    connectionDetailsAlreadySaved.value = R.clone(props.configuration.params);

                    errorAlert.title = null;
                    errorAlert.body = null;
                    if (
                        !emptySampleResponse.value &&
                        !props.configuration.isSampleUploaded &&
                        !props.configuration.response.data &&
                        !props.isFinalized
                    ) {
                        loading.value = true;
                        emptySampleResponse.value = false;

                        exec(
                            KafkaAPI.testCredentialsAndCreateSample(
                                props.configuration.params,
                                props.configuration.fileType,
                            ),
                        )
                            .then((res: any) => {
                                loading.value = false;

                                finalSample.value = null;
                                if (!res.data.length) {
                                    emptySampleResponse.value = true;
                                    errorAlert.title = 'Failed to retrieve sample.';
                                } else {
                                    props.configuration.response.data = reduceSampleValues(limitResponse(res.data, 20)); // eslint-disable-line no-param-reassign
                                    if (props.configuration.response.selectedItems) {
                                        props.configuration.response.selectedItems.splice(0);
                                    }
                                    emit('next-tab');
                                }
                                emit('save-changes');
                            })
                            .catch((error) => {
                                if (error && error.response.data) {
                                    modifiedErrorMessage(error.response.data);
                                }
                                (root as any).$toastr.e('PubSub mechanism connection failed', 'Error');

                                loading.value = false;
                            });
                    } else {
                        // eslint-disable-next-line no-param-reassign
                        props.configuration.response.data = limitResponse(
                            // eslint-disable-line no-param-reassign
                            props.configuration.isSampleUploaded ? props.sample : props.configuration.response.data,
                            20,
                        );
                        emptySampleResponse.value = false;

                        emit('next-tab');
                    }
                }
            }
        };

        const validate = async () => {
            return kafkaValidationRef.value.validate();
        };

        const sampleUploaded = async (event: any) => {
            const file = event.target.files[0];
            await emit('sample-cropped', false);
            switch (props.configuration.fileType) {
                case 'json':
                    await emit('files-changed', { sample: file });
                    await parseJSON(file);
                    if (!props.sample) {
                        emptySampleResponse.value = true;
                    }
                    break;
                case 'xml':
                    invalidFormat.value = await checkInvalidXML(file);
                    if (invalidFormat.value) {
                        emptySampleResponse.value = true;
                        emit('files-changed', { sample: null });
                        emit('sample-uploaded', null);
                        (root as any).$toastr.e('Invalid xml format!', 'Error');
                    } else {
                        await emit('files-changed', { sample: file });
                        await parseXML(file);
                    }
                    break;
                default:
                // Do nothing
            }
            await validate();
        };

        const jobConfig = computed(() => {
            if (props.configuration.response.data && props.basePath) {
                let selectedItems = [];
                const { basePath }: { basePath: string | null } = props;
                let multiple = false;

                if (props.configuration?.response?.selectedItems) {
                    selectedItems = props.configuration.response.selectedItems;
                }

                if (isSampleArray.value) {
                    multiple = true;
                }

                return {
                    basePath,
                    multiple,
                    selectedItems,
                };
            }

            return null;
        });

        watch(
            () => jobConfig.value,
            (config: any) => {
                if (!props.completed) {
                    emit('job-config-change', config);
                }
            },
        );

        const resetFileFormat = async () => {
            clearFiles();
            await emit('sample-uploaded', null);
            retrieveNewFileTypeSample.value = true;
        };

        const checkSASLMechanism = async () => {
            if (props.configuration.params.saslMechanism === 'None') {
                await emit('reset-connection-details');
            }
        };

        const sampleRetrievedFromDescription = computed(() =>
            props.configuration.isSampleUploaded
                ? 'Sample uploaded by the user'
                : 'Kafka response retrieved when testing the Kafka connection',
        );

        const sampleSummaryText = computed(() =>
            props.configuration.isSampleUploaded ? 'Uploaded Sample' : 'Kafka Response',
        );

        const sampleMayBeCroppedMessage = computed(() =>
            props.configuration.fileType
                ? '- Sample may be cropped if required. Ensure that a small sample contains all necessary fields.'
                : '',
        );

        const sampleCroppedMessage = computed(() => (props.configuration.isSampleCropped ? 'cropped, ' : ''));

        return {
            dateYesterday,
            kafkaValidationRef,
            formatBytes,
            sampleFile,
            sampleRef,
            validate,
            validateAndProceed,
            resettingPassword,
            disableDataTypeChange,
            urlRef,
            editingURL,
            saslMechanisms,
            isSampleArray,
            separator,
            finalSample,
            modifyFinalSample,
            loading,
            errorAlert,
            invalidFormat,
            sampleUploaded,
            acceptedFiles,
            clearFiles,
            emptySampleResponse,
            retrieveNewFileTypeSample,
            resetFileFormat,
            checkSASLMechanism,
            sampleRetrievedFromDescription,
            sampleSummaryText,
            sampleCroppedMessage,
            sampleMayBeCroppedMessage,
        };
    },
});
