






























































































































































































































































import 'prismjs';
import 'prismjs/components/prism-json';
import { defineComponent, computed, ref, reactive } from '@vue/composition-api';
import { ValidationProvider, extend, ValidationObserver } from 'vee-validate';
import { required, min_value } from 'vee-validate/dist/rules'; // eslint-disable-line
import { useAxios } from '@vue-composable/axios';
import { FormBlock, TwButton, JsonParser } from '@/app/components';
import { useFilters } from '@/app/composable';
import { InternalAPI } from '@/modules/data-checkin/api';
import AccessTokenModal from '@/modules/user/views/AccessTokenModal.vue';
import { useHarvester } from '../../composable';

extend('required', {
    ...required,
    message: (field) => {
        const { splitLowerCase } = useFilters();

        const fieldName = splitLowerCase(field);
        return `The ${fieldName} is required`;
    },
});

extend('min_value', {
    ...min_value, // eslint-disable-line
    message: 'Add at least one schedule.',
});

export interface Param {
    key: string;
    value: string;
    sensitive: boolean;
}

export default defineComponent({
    name: 'InternalApiConfiguration',
    model: {
        prop: 'configuration',
    },
    components: {
        FormBlock,
        JsonParser,
        TwButton,
        ValidationObserver,
        ValidationProvider,
        AccessTokenModal,
    },
    props: {
        configuration: {
            type: Object,
            required: true,
        },
        sample: {
            type: [Object, Array],
            required: false,
        },
        files: {
            type: Object,
            required: true,
        },
        activeTab: {
            type: Number,
            required: true,
        },
        completed: {
            type: Boolean,
            default: true,
        },
        jobId: {
            type: Number,
            required: true,
        },
        stepId: {
            type: Number,
            required: true,
        },
    },
    setup(props, { emit, root }) {
        const { formatBytes } = useFilters();
        const { exec } = useAxios();
        const schedulesChecked = ref<boolean>(false);
        const apiValidationRef = ref<any>(false);
        const showChangeScheduleModal = ref(false);
        const showAccessTokenModal = ref(false);
        const newPeriod = ref(null);
        const loginResponse = ref<any>(null);
        const loginResponseKeys = ref<string[]>([]);
        const invalidLoginBody = ref<boolean>(false);
        const invalidQueryBody = ref<boolean>(false);
        const dateYesterday = computed(() => {
            const date = new Date();
            date.setDate(date.getDate() - 1);
            return date;
        });
        const finalSample = ref<any>(props.sample);

        const dataTypes = {
            text: {
                label: 'Text only',
            },
            textBinary: {
                label: 'Text and Binary',
            },
        };

        const postURL = ref<any>(null);
        const sampleFile = computed(() => props.files.sample);
        const sampleRef = ref<any>(null);
        const fileRef = ref<any>(null);
        const postURLToCopy = ref<any>(null);

        const { parseJSON } = useHarvester(root, emit);

        if (
            props.configuration.params.uploadQueryId === null ||
            props.configuration.params.uploadQueryId.length === 0
        ) {
            exec(InternalAPI.create(props.jobId, props.stepId)).then((resCreate: any) => {
                postURL.value = `${process.env.VUE_APP_BACKEND_URL}/api/upload/${resCreate.data.id}`;
                props.configuration.params.uploadQueryId = resCreate.data.id; // eslint-disable-line no-param-reassign
                emit('save-step');
            });
        } else {
            postURL.value = `${process.env.VUE_APP_BACKEND_URL}/api/upload/${props.configuration.params.uploadQueryId}`;
        }

        const errorAlert: any = reactive({
            title: null,
            body: null,
        });

        const validate = async () => {
            return apiValidationRef.value.validate();
        };

        const sampleUploaded = async (event: any) => {
            const file = event.target.files[0];
            await emit('sample-cropped', false);
            switch (props.configuration.fileType) {
                case 'json':
                    await emit('files-changed', { sample: file });
                    await parseJSON(file);
                    break;

                default:
                // Do nothing
            }
            await validate();
        };

        const validateAndProceed = async () => {
            if (apiValidationRef.value) {
                const valid = await apiValidationRef.value.validate();
                if (valid) {
                    emit('next-tab');
                }
            }
        };

        const copyToClipboard = () => {
            postURLToCopy.value = document.querySelector('#url');
            if (postURLToCopy.value) {
                postURLToCopy.value.setAttribute('type', 'text');
                postURLToCopy.value.select();
                document.execCommand('copy');
                postURLToCopy.value.setAttribute('type', 'hidden');
            }
        };

        const sampleMayBeCroppedMessage = computed(() =>
            props.configuration.fileType
                ? '- Sample may be cropped if required. Ensure that a small sample contains all necessary fields.'
                : '',
        );

        const sampleCroppedMessage = computed(() => (props.configuration.isSampleCropped ? 'cropped, ' : ''));

        return {
            invalidLoginBody,
            invalidQueryBody,
            schedulesChecked,
            errorAlert,
            apiValidationRef,
            dateYesterday,
            finalSample,
            newPeriod,
            loginResponse,
            loginResponseKeys,
            showChangeScheduleModal,
            showAccessTokenModal,
            validate,
            dataTypes,
            fileRef,
            formatBytes,
            sampleFile,
            sampleRef,
            sampleUploaded,
            validateAndProceed,
            copyToClipboard,
            postURL,
            postURLToCopy,
            sampleCroppedMessage,
            sampleMayBeCroppedMessage,
        };
    },
});
