<template>
    <div>
        <div class="flex items-center mt-4 mb-1 space-x-6">
            <div class="my-auto text-sm text-neutral-700">Masking Character</div>
            <div class="w-40 pb-2">
                <select
                    v-model="selectedField.options.maskingChar"
                    aria-label="Masking Character"
                    class="w-full py-1 mt-2 bg-white form-select sm:text-sm sm:leading-5"
                >
                    <option value="*">*</option>
                    <option value="-">-</option>
                </select>
            </div>
        </div>
        <div class="flex items-center mt-3 mb-1 space-x-6">
            <div class="my-auto text-sm text-neutral-700">Padding Character</div>
            <div class="w-40 pb-2">
                <select
                    v-model="selectedField.options.paddingChar"
                    aria-label="Padding Character"
                    class="w-full h-full py-1 mt-2 bg-white form-select sm:text-sm sm:leading-5"
                >
                    <option value="#">#</option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
    name: 'Masking',
    props: {
        selectedField: {
            type: Object,
            required: true,
        },
    },
});
</script>
