import { ref, computed } from '@vue/composition-api';

export function useSuggestions(model: any) {
    const domainId = computed<number>(() => (model ? model.id : -1));
    const showModal = ref<boolean>(false);
    const domainConcepts = computed(() => {
        return model.children.reduce((concepts: any[], obj: any) => {
            if (obj.children && obj.children.length > 0) {
                concepts.push({
                    id: obj.id,
                    name: obj.name,
                });
            }
            return concepts;
        }, []);
    });

    const domainStandards = computed(() => model.standardsMapping);

    const initConcept = () => ({
        name: '',
        description: '',
        type: null,
        domainId: domainId.value,
        parentId: null,
        referenceConceptId: null,
        relatedTerms: [],
        standardsMapping: null,
    });

    return { showModal, domainId, domainConcepts, domainStandards, initConcept };
}
