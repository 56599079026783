import dayjs from 'dayjs';

export function useCleaning(types: string[]) {
    const getConditionText = (condition: string) => {
        switch (condition) {
            case 'EQUAL':
                return 'equal to';
            case 'NOT_EQUALS':
                return 'not equal to';
            case 'GREATER_THAN':
                return 'greater than';
            case 'SMALLER_THAN':
                return 'smaller than';
            default:
                return '';
        }
    };
    const getDatetimeText = (dateString: string, type: string | null = null) => {
        const fieldTypes = type ? [type] : types;
        if (fieldTypes.includes('datetime')) {
            return `${dayjs(dateString).format('DD/MM/YYYY HH:mm')} (local timezone)`;
        }
        if (fieldTypes.includes('date')) {
            return `${dayjs(dateString).format('DD/MM/YYYY')}`;
        }
        if (fieldTypes.includes('time')) {
            let date = new Date(dateString);
            // eslint-disable-next-line no-restricted-globals
            if (isNaN(date.getTime())) {
                date = new Date(
                    new Date().setHours(
                        parseInt(dateString.substring(0, 2), 10),
                        parseInt(dateString.substring(3, 5), 10),
                        0,
                        0,
                    ),
                );
            }
            return `${dayjs(dateString).format('HH:mm')} (local timezone)`;
        }
        return '';
    };
    const setDateTime = (string: string) => {
        if (types.includes('datetime')) {
            return dayjs(string).format('YYYY-MM-DDTHH:mm:ssZ');
        }
        if (types.includes('date')) {
            return dayjs(string).format('YYYY-MM-DD');
        }
        if (types.includes('time')) {
            return dayjs(string).format('HH:mm:ssZ');
        }
        return '';
    };
    const getConstraintText = (constraint: any, type: string | null = null, hasStats: any = null) => {
        const fieldTypes = type ? [type] : types;
        switch (constraint.type) {
            case 'RANGE':
                // eslint-disable-next-line no-case-declarations
                let { from } = constraint.details;
                // eslint-disable-next-line no-case-declarations
                let { to } = constraint.details;
                if (fieldTypes.includes('datetime') || fieldTypes.includes('date') || fieldTypes.includes('time')) {
                    from = getDatetimeText(constraint.details.from, type);
                    to = getDatetimeText(constraint.details.to, type);
                }
                return [hasStats ? 'Field values not between' : 'Field values must be between', from, 'and', `${to},`];
            case 'MANDATORY':
                return [
                    hasStats ? 'Field values which were' : 'Field values must',
                    hasStats ? 'null,' : 'not be null,',
                ];
            case 'REGULAR_EXPRESSION':
                return [
                    hasStats
                        ? 'Field values not having an exact match with the'
                        : 'Field values must have an exact match with the',
                    constraint.details.regularExpression,
                    ' regular expression,',
                ];
            case 'UNIQUE':
                return [
                    hasStats ? 'Field values which were' : 'Field values must',
                    hasStats ? 'not unique' : 'be unique,',
                ];
            case 'SET_MEMBERSHIP':
                return [
                    hasStats ? 'Field values not in the' : 'Field values must be in the',
                    constraint.details.codeList,
                    'code list,',
                ];
            case 'CROSS_FIELD':
                // eslint-disable-next-line no-case-declarations
                let text = '';
                constraint.details.conditions.forEach((condition: any) => {
                    if (condition.logicalOperator) {
                        text += ` ${condition.logicalOperator.toLowerCase()} `;
                    }
                    text += `${getConditionText(condition.conditionalOperator)} ${condition.field.replace(
                        /__/g,
                        ' > ',
                    )}`;
                });
                return [hasStats ? 'Field values not satisfying the condition' : 'Field values must be', `${text},`];
            case 'FOREIGN_KEY':
                return [
                    'Field values of referenced field',
                    constraint.details.field.replace(/__/g, ' > '),
                    hasStats ? 'which did not exist,' : 'must exist,',
                ];
            default:
                return [''];
        }
    };
    const getOutliersRuleText = (constraint: any, type: string | null = null, hasStats: any = null) => {
        const fieldTypes = type ? [type] : types;
        switch (constraint.outliersRule.type) {
            case 'DROP':
                return [hasStats ? 'were' : 'otherwise the whole row will be', 'dropped.'];
            case 'DEFAULT_VALUE':
                // eslint-disable-next-line no-case-declarations
                let value = constraint.outliersRule.replaceValue;
                if (fieldTypes.includes('datetime') || fieldTypes.includes('date') || fieldTypes.includes('time')) {
                    value = getDatetimeText(constraint.outliersRule.replaceValue, type);
                }
                return [hasStats ? 'were replaced by' : 'otherwise they will be replaced by', `${value}.`];
            case 'PREVIOUS_VALUE':
                // eslint-disable-next-line no-case-declarations
                let secondValue = constraint.outliersRule.secondaryRule.replaceValue;
                if (fieldTypes.includes('datetime') || fieldTypes.includes('date') || fieldTypes.includes('time')) {
                    secondValue = getDatetimeText(constraint.outliersRule.secondaryRule.replaceValue, type);
                }
                return [
                    hasStats ? 'were replaced by' : 'otherwise they will be replaced by',
                    'their previous value',
                    ' or by ',
                    secondValue,
                    hasStats ? 'if there was no previous value.' : 'if there is no previous value.',
                ];
            case 'MOST_FREQUENT_VALUE':
                return [
                    hasStats ? 'were replaced by' : 'otherwise they will be replaced by',
                    "the field's most frequent value.",
                ];
            case 'MEAN_VALUE':
                return [
                    hasStats ? 'were replaced by' : 'otherwise they will be replaced by',
                    "the field's mean value.",
                ];
            case 'MEDIAN_VALUE':
                return [
                    hasStats ? 'were replaced by' : 'otherwise they will be replaced by',
                    "the field's median value.",
                ];
            case 'MIN_VALUE':
                return [
                    hasStats ? 'were replaced by' : 'otherwise they will be replaced by',
                    "the field's minimum value.",
                ];
            case 'MAX_VALUE':
                return [
                    hasStats ? 'were replaced by' : 'otherwise they will be replaced by',
                    "the field's maximum value.",
                ];
            case 'MOST_SIMILAR':
                return [
                    hasStats ? 'were replaced by' : 'otherwise they will be replaced by',
                    'their most similar value in the code list.',
                ];
            default:
                return [''];
        }
    };

    return { getConstraintText, getOutliersRuleText, getDatetimeText, setDateTime };
}
