var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"h-20 m-1 bg-white border border-gray-300 shadow cursor-pointer",class:_vm.classes,on:{"click":_vm.conceptClicked}},[_c('div',{staticClass:"flex h-full p-2 border-l-4",class:{
                'border-primary-700': _vm.selected,
                'border-transparent': !_vm.selected,
            }},[_c('div',{staticClass:"flex flex-col w-2/5"},[_c('div',[_c('h2',{staticClass:"text-lg font-bold text-neutral-800 min-h-7"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex items-center space-x-2"},[(_vm.failedTransformationReason)?_c('ExclamationIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.failedTransformationReason[0]),expression:"failedTransformationReason[0]"}],staticClass:"w-5 h-5 text-red-600"}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.concept.source.title))]),_c('span',{staticClass:"px-2 text-sm capitalize rounded text-neutral-100 bg-neutral-600"},[_vm._v(_vm._s(_vm.concept.source.type))])],1)])]),_c('field-path',{attrs:{"field":_vm.concept.source}})],1)]),_c('div',{staticClass:"w-1/5"},[(_vm.concept.target.id)?_c('prediction-score',{attrs:{"score":_vm.concept.prediction && _vm.concept.prediction.score,"user-defined":_vm.concept.temp && _vm.concept.temp.userDefined}}):(_vm.selected)?_c('div',{staticClass:"h-full mx-2 align-middle border border-dashed",class:{
                        'border-primary-700 bg-primary-100': _vm.inDropZone,
                        'border-neutral-400 bg-neutral-100': !_vm.inDropZone,
                    }},[_c('draggable',{ref:"dropAreaRef",staticClass:"flex flex-wrap content-center justify-center h-full text-center",class:{ 'opacity-0': !_vm.inDropZone && _vm.dragActive },attrs:{"group":{ name: 'newConcepts', put: true, pull: false },"list":_vm.droppedConcept},on:{"add":_vm.add}},[(_vm.droppedConcept.length === 0 && !_vm.dragActive)?_c('div',{staticClass:"text-sm italic text-neutral-500"},[_vm._v(" Drag and drop a field here ")]):_vm._e()])],1):_vm._e()],1),_c('div',{staticClass:"w-2/5"},[_c('div',[_c('h2',{staticClass:"flex items-center justify-between text-lg font-bold text-neutral-800 min-h-7"},[_c('div',{staticClass:"flex flex-row items-start justify-between overflow-hidden whitespace-nowrap"},[_c('div',{staticClass:"flex items-center overflow-hidden whitespace-nowrap"},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.fullPath),expression:"fullPath"}],staticClass:"truncate"},[_vm._v(_vm._s(_vm.concept.target.title))]),_c('span',{staticClass:"flex flex-1 px-2 ml-2 text-sm capitalize rounded",class:{
                                        'text-red-700 bg-red-200': _vm.concept.target.type !== _vm.concept.source.type,
                                        'text-neutral-100 bg-neutral-600':
                                            _vm.concept.target.type === _vm.concept.source.type,
                                    }},[_vm._v(_vm._s(_vm.concept.target.type))])]),(_vm.concept.target.id)?_c('button',{staticClass:"p-1 text-neutral-500 hover:text-red-700",attrs:{"type":"button"},on:{"click":function($event){_vm.$emit('clear-mapping');
                                    $event.stopImmediatePropagation();}}},[_c('svg',{staticClass:"w-4 h-4",attrs:{"viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z","clip-rule":"evenodd"}})])]):_vm._e()])]),_c('div',{staticClass:"flex items-center mt-2 space-x-1 text-sm text-neutral-600"},[_c('field-path',{attrs:{"field":_vm.concept.target}})],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }