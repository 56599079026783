













import { defineComponent, reactive, ref } from '@vue/composition-api';
import { useAxios } from '@vue-composable/axios';
import { FormModal } from '@/app/components';
import AccessTokens from './AccessTokens.vue';
import { AccessTokenAPI } from '../api';

export default defineComponent({
    name: 'AccessTokenModal',
    components: { FormModal, AccessTokens },
    props: {
        scope: {
            type: Object,
        },
    },
    setup(props, { root, emit }) {
        const { exec } = useAxios();

        const generatedToken = ref(false);

        const tokenGeneration: any = reactive({
            name: null,
            token: '',
            scopes: [],
        });

        const generateToken = () => {
            tokenGeneration.scopes.push(props.scope?.name);

            const payload = {
                name: tokenGeneration.name,
                scopes: tokenGeneration.scopes,
                createdById: 0,
            };

            exec(AccessTokenAPI.generateToken(payload))
                .then((res: any) => {
                    tokenGeneration.token = res.data.key;

                    generatedToken.value = true;
                    tokenGeneration.name = null;
                    tokenGeneration.scopes = [];
                })
                .catch(() => {
                    (root as any).$toastr.e('The token could not be generated due to an error.', 'Error');
                });
        };

        return { emit, tokenGeneration, generatedToken, generateToken };
    },
});
