




























import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
    name: 'PredictionScore',
    props: {
        score: {
            type: Number,
            default: 0,
        },
        userDefined: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const colorClass = computed(() => {
            if (props.score >= 0.75) return 'bg-green-500';
            if (props.score >= 0.5) return 'bg-yellow-500';
            return 'bg-red-500';
        });

        return { colorClass };
    },
});
