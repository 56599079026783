














































































































import { defineComponent, ref, computed, inject, Ref, watch } from '@vue/composition-api';
import Draggable from 'vuedraggable';
import { ExclamationIcon } from '@vue-hero-icons/outline';
import PredictionScore from './PredictionScore.vue';
import { FieldPath } from '../../components';

const Dragster = require('@/app/assets/js/dragster.js'); // eslint-disable-line @typescript-eslint/no-var-requires

export default defineComponent({
    name: 'ConceptView',
    components: { PredictionScore, Draggable, FieldPath, ExclamationIcon },
    props: {
        concept: {
            type: Object,
            required: true,
        },
        selected: {
            type: Boolean,
            default: false,
        },
        message: {
            type: Object,
            required: false,
        },
    },
    setup(props, { emit }) {
        const dragActive: Ref<boolean> = inject('dragging', ref(false));
        const inDropZone = ref(false);
        const dropAreaRef = ref<any>(null);

        const isMacOS = window.navigator.userAgent.indexOf('Mac OS') !== -1;

        const conceptClicked = (event: MouseEvent) => {
            emit('toggle-selection', (event.metaKey && isMacOS) || (event.ctrlKey && !isMacOS));
        };

        const failedTransformationReason = computed(() => {
            if (
                props.message &&
                props.message.stats &&
                props.message.failedTransformations &&
                props.concept.source.id
            ) {
                let fieldId: any = null;
                Object.entries(props.message.stats).forEach((entry: any) => {
                    const [key, value]: any = entry;
                    if (props.concept.source.id === value.source_id) {
                        fieldId = key;
                    }
                });

                if (fieldId) {
                    return props.message.failedTransformations[fieldId];
                }
            }
            return null;
        });

        const droppedConcept = ref([]);
        const classes = computed(() => {
            if ((props.concept.temp && props.concept.temp.invalid) || failedTransformationReason.value) {
                if (props.selected) {
                    return 'rounded-r border-primary-700 bg-red-100';
                }
                return 'rounded bg-red-100 border-red-600';
            }
            if (props.selected) return 'rounded-r border-primary-700';
            return 'rounded border-white';
        });
        const add = () => {
            if (!inDropZone.value) {
                droppedConcept.value.splice(0);
            }

            if (droppedConcept.value.length > 0) {
                emit('concept-added', droppedConcept.value[0]);
                droppedConcept.value.splice(0); // Clear drop area
            }

            inDropZone.value = false;
        };

        const trimPath = () => {
            props.concept.target.path.splice(-1, 1);
            props.concept.target.parentIds.splice(-1, 1);
        };

        const handleDragEnter = () => {
            inDropZone.value = true;
        };

        const handleDragLeave = () => {
            inDropZone.value = false;
        };

        const fullPath = computed(() => {
            const path: Array<string> = [...props.concept.target.path];
            if (props.concept.target.title) {
                path.push(props.concept.target.title);
            }
            return path.join(' > ');
        });

        // Adding and removing drag handlers to correctly monitor when a field is dragged inside the designated area
        // Dragster is used to provide custom drag events that ignore child elements from triggering the
        // dragleave event, making the whole process problematic
        let dragster: any = null;
        watch(dropAreaRef, (current: any, prev: any) => {
            if (current && !prev) {
                dragster = new Dragster(current.$el);
                current.$el.addEventListener('dragster:enter', handleDragEnter, false);
                current.$el.addEventListener('dragster:leave', handleDragLeave, false);
            }

            if (prev && !current && dragster) {
                dragster.removeListeners();
                dragster = null;
            }
        });

        return {
            add,
            classes,
            droppedConcept,
            trimPath,
            dragActive,
            dropAreaRef,
            inDropZone,
            conceptClicked,
            fullPath,
            failedTransformationReason,
        };
    },
});
