






















































































































































































































































































import * as R from 'ramda';
import { defineComponent, ref, computed } from '@vue/composition-api';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules'; // eslint-disable-line
import VueMultiselect from 'vue-multiselect';

extend('required', {
    ...required,
    message: '{_field_} is required',
});

export default defineComponent({
    name: 'SuggestionModal',
    model: {
        prop: 'suggestion',
        event: 'updateSuggestion',
    },
    props: {
        suggestion: {
            type: Object,
            required: true,
        },
        concepts: {
            type: Array,
            required: true,
        },
        standards: {
            type: Array,
            required: true,
        },
    },
    components: { ValidationObserver, ValidationProvider, VueMultiselect },
    setup(props, { emit }) {
        const suggestionRef = ref<any>(null);
        const newSuggestion = ref(R.clone(props.suggestion));
        const types = ['boolean', 'date', 'datetime', 'double', 'integer', 'string', 'time', 'object', 'base64binary'];
        const sortedConcepts = computed(() => props.concepts.sort((a: any, b: any) => a.name.localeCompare(b.name)));

        const addTag = (tag: string) => {
            newSuggestion.value.relatedTerms.push(tag);
        };

        const save = () => {
            const valid = suggestionRef.value.validate();
            if (!newSuggestion.value.parentId) {
                newSuggestion.value.type = 'object';
            }
            emit('updateSuggestion', newSuggestion.value);
            if (valid) {
                emit('ok');
            }
        };

        return { addTag, types, save, suggestionRef, newSuggestion, sortedConcepts };
    },
});
