<template>
    <div>
        <div class="flex-grow mt-4 text-xs font-semibold tracking-wide uppercase text-neutral-700">
            Grouping Technique
        </div>
        <div class="mt-2 space-x-4">
            <div class="inline-flex items-center">
                <input
                    id="boolean-grouping"
                    v-model="selectedField.options.show"
                    type="radio"
                    class="form-radio text-primary-600 disabled:text-neutral-400 disabled:cursor-not-allowed"
                    :value="true"
                />
                <label for="boolean-grouping" class="ml-2 text-sm text-neutral-700">Group Values</label>
            </div>
            <div class="inline-flex items-center">
                <input
                    id="boolean-drop"
                    v-model="selectedField.options.show"
                    type="radio"
                    class="form-radio text-primary-600 disabled:text-neutral-400 disabled:cursor-not-allowed"
                    :value="false"
                />
                <label for="boolean-drop" class="ml-2 text-sm text-neutral-700">Drop Column</label>
            </div>
        </div>
        <div v-if="selectedField.options.show" class="text-sm text-gray-600">
            {{ AnonymisationDescriptions.groupBoolean }}
        </div>
        <div v-else class="text-sm text-gray-600">
            {{ AnonymisationDescriptions.dropBoolean }}
        </div>
    </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { AnonymisationDescriptions } from '../../../constants';

export default defineComponent({
    name: 'BooleanGroup',
    props: {
        selectedField: {
            type: Object,
            required: true,
        },
    },
    setup() {
        return { AnonymisationDescriptions };
    },
});
</script>
