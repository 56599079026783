export interface DomainID {
    uid: string;
    majorVersion: number;
    name: string;
}

export interface NameID {
    uid: string;
    name: string;
}

export interface NameIDType {
    uid: string;
    name: string;
    type: string | null;
}

export interface AssetStructure {
    kafkaTopic: string | null;
    streamingStatus: string | null;
    dataType: 'text' | 'textBinary';
    source: 'api' | 'kafka' | 'file';
    type: 'json' | 'other';
    domain: DomainID;
    primaryConcept: NameID | null;
    otherConcepts: NameID[];
    spatialFields: NameIDType[];
    temporalFields: NameIDType[];
    periodicity: string;
    schema: unknown;
}

export enum StreamingStatus {
    Active = 'active',
    Completed = 'completed',
}
