import { computed, ref, watch } from '@vue/composition-api';
import Fuse from 'fuse.js';
import * as R from 'ramda';
import { StatusCode } from '@/modules/asset/constants';
import { ModelConcept } from '../types/model-concept.interface';
import { S } from '@/app/utilities';

export function useModelSearch(list: any, dataModel: any, options: Fuse.IFuseOptions<ModelConcept>) {
    const sortByName = R.sortWith([R.ascend(R.prop('name'))]);
    const fuse = ref<Fuse<any>>(new Fuse([], options));
    const query = ref<string>('');
    const searchResults = ref<any>([]);

    watch(list, () => {
        if (list.value.length > 0) {
            const stableConcepts = list.value[0].children.filter((c: any) => c.status === 'stable');
            fuse.value = new Fuse(
                stableConcepts.sort((a: any, b: any) => a.name.localeCompare(b.name)),
                options,
            );
        } else {
            fuse.value = new Fuse([], options);
        }
    });

    const search = () => {
        searchResults.value = fuse.value.search(query.value);
    };

    const result = computed(() => {
        let stableConcepts: any = [];
        if (list.value.length > 0) {
            stableConcepts = [
                {
                    ...list.value[0],
                    children: sortByName(list.value[0].children.filter((c: any) => c.status === 'stable')),
                },
            ];
        }

        if (query.value.trim()) {
            if (searchResults.value.length > 0) {
                return [
                    {
                        ...stableConcepts[0],
                        children: R.pluck('item' as any, searchResults.value), // Sorting is done by Fuse, by relevance
                    },
                ];
            }
            return [];
        }

        return sortByName(stableConcepts);
    });

    /**
     * Transforms a list of concepts into flat map
     * where the key is the id and the value is the concept itself
     * @param concepts List of concepts to transform
     *
     * @returns A map where the key is the concept id and value is the concept
     * to which the id belongs to
     */
    const fillMap = (concepts: any) => {
        return concepts.reduce((acc: any, res: { id: number; status: StatusCode; children?: any[] }) => {
            if (res.status !== StatusCode.Deprecated) {
                acc[res.id] = res;
                if (S.has('children', res) && res.children && res.children.length > 0) {
                    return {
                        ...acc,
                        ...fillMap(res.children),
                    };
                }
            }

            return acc;
        }, {});
    };

    const fullMap = computed(() => {
        return fillMap(R.is(Array, dataModel) ? dataModel : [dataModel]);
    });

    const findById = (id: number) => {
        return fullMap.value[id];
    };

    return { fuse, query, search, result, findById };
}
